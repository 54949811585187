import React, { useState, useEffect } from "react";
import styles from "./ApplyPage.module.css";
import MyPicture from "./images/20200227_164938.jpg";
import Header from "./Header";
import Datenschutz from "./images/Datenschutz.pdf";
import { sendEmail } from "./SendApplyMessage.js";

const ApplyPage = ({ onClose }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    checkboxChecked: false,
    nameError: "",
    emailError: "",
    messageError: "",
    checkboxError: "",
  });

  const handleInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      checkboxChecked: !prevState.checkboxChecked,
      checkboxError: "",
    }));
  };

  const handleSendMessageClick = () => {
    // Clear all errors
    setState({
      ...state,
      nameError: "",
      emailError: "",
      messageError: "",
      checkboxError: "",
    });

    const { name, email, message, checkboxChecked } = state;

    // Überprüfe, ob alle Felder ausgefüllt sind und die Checkbox angeklickt wurde
    let isValid = true;

    if (!name) {
      setState((prevState) => ({
        ...prevState,
        nameError: "Bitte gib deinen Namen ein.",
      }));
      isValid = false;
    }

    if (!email) {
      setState((prevState) => ({
        ...prevState,
        emailError: "Bitte gib deine E-Mail-Adresse ein.",
      }));
      isValid = false;
    }

    if (!message) {
      setState((prevState) => ({
        ...prevState,
        messageError: "Bitte gib deine Nachricht ein.",
      }));
      isValid = false;
    }

    if (!checkboxChecked) {
      setState((prevState) => ({
        ...prevState,
        checkboxError: "Bitte akzeptiere die Datenschutzbestimmungen.",
      }));
      isValid = false;
    }

    if (isValid) {
      // Perform the action (e.g., sending the email)
      sendEmail(name, email, message);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const {
    name,
    email,
    message,
    checkboxChecked,
    nameError,
    emailError,
    messageError,
    checkboxError,
  } = state;

  return (
    <div className={styles.overlay}>
      <div className={styles.applyPage}>
        <span className={styles.closeApplyPage} onClick={onClose}>
          &times;
        </span>
        <div className={styles.wrapper}>
          <div className={styles.aboutMe}>
            <div className={styles.myPicture}>
              <img src={MyPicture} alt="My Picture" />
            </div>
            <div className={styles.myNameAndRole}>
              <p className={styles.MyName}>~DGKS Claudia Maxim</p>
              <p className={styles.MyRole}> Büroleitung und Personalwesen </p>
            </div>
          </div>

          <div className={styles.contactMe}>
            <div className={styles.kontakt}>
              <h3 style={{ fontSize: "2em" }}>
                JETZT ONLINE BEWERBEN!<br></br>
              </h3>
              <Header>Wir freuen uns darauf Sie kennenzulernen</Header>
            </div>
            <div className={styles.nameInput}>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="NAME*"
                value={name}
                onChange={handleInputChange}
              />
              <div className={styles.errorText}>{nameError}</div>
            </div>
            <div className={styles.emailInput}>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="EMAIL*"
                value={email}
                onChange={handleInputChange}
              />
              <div className={styles.errorText}>{emailError}</div>
            </div>
            <div className={styles.messageInput}>
              <textarea
                type="text"
                id="message"
                name="message"
                placeholder="NACHRICHT*"
                value={message}
                onChange={handleInputChange}
              />

              <div className={styles.errorText}>{messageError}</div>
            </div>
            <div className={styles.datenschutzContainer}>
              <label>
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  onChange={handleCheckboxChange}
                ></input>
                <span className={styles.datenschutzText}>
                  Ich bin damit einverstanden, dass meine personenbezogenen
                  Daten zur Bearbeitung dieser Anfrage verarbeitet werden
                  dürfen. Mehr Informationen in unserer
                  <a
                    href={Datenschutz}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Datenschutzbestimmungen
                  </a>
                  .
                </span>
              </label>
            </div>
            <div className={styles.errorText}>{checkboxError}</div>

            <div className={styles.buttonSend}>
              <button type="button" onClick={handleSendMessageClick}>
                Nachricht senden
              </button>
            </div>
          </div>
        </div>
        <div className={styles.mobilePhoneNumber}>
          <p>
            oder telefonisch unter: <br></br>&#9990;
            <span className={styles.span_p}>0699 15072440</span>&#9990;
            <span className={styles.span_p}>0040 744492395</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApplyPage;
