/*
  Video gemacht mit Canva.com
*/


import React, { Component } from "react";
import styles from "./VideoPage.module.css";
import videoFile from "./images/Auf_den_punkt_gebracht_2.mp4";

export default class VideoPage extends Component {
  render() {
    return (
      <div id="fourth-page" className={styles.fourthPage}>
        <h1
          style={{
            marginTop: "10vh",
            textAlign: "center",
            color: "#80358c",
            fontSize: "3vh",
          }}
        >
          Ihr Wohlbefinden steht für uns an erster Stelle!
        </h1>
        <div className={styles.underline}></div>
        <div className={styles.videoContainer}>
          <video
            id="myVideo"
            className={styles.video}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={videoFile} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.videoOverlay}></div>
        </div>

        <div className={styles.underline}></div>
      </div>
    );
  }
}
