
import TitlePage from "./components/TitlePage.js";
import HoverInfoPage from "./components/HoverInfoPage.js";
import ImageDetailsPage from "./components/ImageDetailsPage.js";
import VideoPage from "./components/VideoPage.js";
import ContactMePage from "./components/ContactMePage.js";
import ContactInfoPage from "./components/ContactInfoPage.js";


import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Pflegeaktiv24";
  });
  return (
    <div style={{ display: "flex", flexDirection: "column", overflowX:"hidden" }}>
      <TitlePage />
      <HoverInfoPage/>
      {/*<ImageDetailsPage />*/}
      <VideoPage />
      <ContactMePage />

      <ContactInfoPage />
    </div>
  );
}

export default App;
